.center_main_index {
    max-width: var(--max-width-container);
    margin: 3em auto;
    padding: 0 20px;
}

.services_index {
    margin: 20px 0;
}

.container_pricipal_servieces {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;

    gap: 20px;
    /* justify-content: center; */
}

/* Popups */


.title_form_my_profile {
    padding: 25px 0 0 25px;
}


.padding_form_my_profile {
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input_save_profile {
    display: flex;
    flex-direction: column;
}

.change_image_my_profile {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(95, 95, 95, 0.74);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.change_image_my_profile:hover {
    cursor: pointer;
}

.info_change_image {
    color: #FFFF;
    font-size: 20px;
    font-family: var(--font-1);
    margin: 1em 0 0 0;
    width: 200px;
    text-align: center;
}

.link_card_service {
    text-decoration: none;
}



.information_services {
    margin: 20px 0 0 0;
    display: flex;
    gap: 20px;
}


/* Information section */

.information_services {
    background: #e7edf5;
}

.center_section_information_services {
    width: 100%;
    max-width: var(--max-width-container);
    margin: auto;
    padding: 0 20px;
}

.title_index {
    font-family: var(--font-1);
    font-size: 1.5em;
    color: var(--gray-text-2);
    margin: 1.5em 0 0 0;
}

.section_interest_card {
    margin: 2em 0;
    display: flex;
    gap: 20px;
}


@media screen and (max-width: 1000px) {
    .section_interest_card {
        flex-direction: column;
    }
}
