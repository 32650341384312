.card_service_dashboard {
    width: 100%;
    padding: 15px;
    background: #ecf3ff;
    border-radius: 7px;
    overflow-y: auto;

    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.section_info_card_service_dashboard {
    display: flex;
    gap: 10px;
}

.image_card_service_dashboard {
    width: 120px;
    height: 68px;

    border-radius: 5px;
    overflow: hidden;
}

.image_card_service_dashboard > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.information_card_service_dashboard {
    width: 200px;
}

.name_card_service {
    color: var(--gray-text-1);
    font-size: 23px;
    font-family: var(--font-1);
}

.description_card_service {
    color: #a0a0a0;
    font-size: 15px;
    font-family: var(--font-1);
}

.state_card_service_dashboard {
    width: 150px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    gap: 7px;
}

.subtitle_reports_card_service_dashboard {
    font-family: var(--font-1);
    color: var(--gray-text-1);
    font-size: 16px;
}

.info_state_card_service_dashboard {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon_danger_ {
    width: 20px;
}

.value_report {
    font-family: var(--font-1);
    color: #fab431;
    font-size: 13px;
}

.icon_checked_ {
    width: 17px;
}

.value_not_report {
    font-family: var(--font-1);
    color: green;
    font-size: 13px;
}

.actions_card_service_dashboard {
    /* width: 100px; */
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 30px;
}

.icon_edit_card_service {
    width: 17px;
    fill: #a3a3a3;
}

.icon_delete_card_service {
    width: 20px;
    fill: #a3a3a3;
}

.icon_edit_card_service:hover,
.icon_delete_card_service:hover {
    cursor: pointer;
}

.visibility_card_service_dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
}

.info_visibility_card_service_dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}
.icon_visibility {
    fill: var(--gray-text-2);
}

.text_visibility_card_service {
    font-family: var(--font-1);
    color: var(--gray-text-3);
}

@media screen and (max-width: 1280px) {
    .section_info_card_service_dashboard {
        overflow-x: auto;
    }
}