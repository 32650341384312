.loading_card_user {
    position: relative;
    height: 100px;
}

.header_loading_card_user {
    background: #f0f0f0;
    height: 33px;
}

.body_loading_card_user {
    background: #f5f5f5;
    height: 67px;
}

.circle_profile_user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e9e9e9;
}

.name_loading_card_user {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    background: #e9e9e9;
}

.animation_load_card_user {
    top: -10px;
    left: 50px;
    width: 200px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 7%, rgba(230, 230, 230, 0.846) 23%, rgba(230, 230, 230, 0.846) 36%,rgba(230, 230, 230, 0.846) 61%, rgba(230, 230, 230, 0.846) 74%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%);
    z-index: 2;
    transform: rotate(4deg) translateX(-20px);
    height: 150%;
    position: absolute;
    animation: card_load infinite 3s;
}

@keyframes card_load {
    0% {
        left: -100%;
    }
    100% {
        left: 110%;
    }
}