.popup_title_admin{
    display: flex;
    align-items:center ;
    gap: 4%;
}

.icon_alert_admin{
    color: var(--status-yellow);
    font-size: 3.125em;
}

@media (min-width: 0) and (max-width: 480px) {
    .title_popup_confirm_changes{
        font-size: 16px;
    }
}