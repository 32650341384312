.card_service_search {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    background: #f9f9f9;
    display: flex;
}

.image_service_search > img {
    width: 200px;
    height: 100%;
}

.info_service_search {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 10px;
}

.header_service_search {
    display: flex;
    /* flex-wrap: wrap; */
    /* align-items: center; */
    flex-direction: column;
}

.name_service_search {
    font-family: var(--font-1   );
    font-size: 1.4em;
    color: var(--gray-text-2);
    margin: 0 10px 0 0;
}

.location_service_search {
    font-family: var(--font-1);
    font-size: 1.1em;
    color: var(--gray-text-6); 
}

.body_service_search {
    display: flex;
    justify-content: space-between;
    background: transparent;
}
.price_service_search {
    font-family: var(--font-1);
    font-size: 1.5em;
    margin: 10px 0 0 0;
    color: #525252; 

    /* color: #1468d6; */
}