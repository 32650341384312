.container_admin{
    display: flex;
    min-width: 320px;
    height: 100vh;
}
.manager_control{
    width: 80%;
    padding: 3% 2% 1.3%;
    display: flex;
    flex-direction: column;
    gap: 2.5%;
}

/*size of the fileds of the dashboard*/

.fieldSize3{width: 3%;}
.fieldSize8{width: 8%;}
.fieldSize15{width: 15%;}
.fieldSize13{width: 13%;}
.fieldSize17{width: 17%;}
.fieldSize20{width: 20%;}


.position_relative{
    position: relative;
}
/* objets dashboard*/

.object_info{
    display: flex;
    gap: 2%;
    align-items: center;

    border-radius: 1em;
    padding: 1%;
    margin-bottom: 1%;
}

.background_object_info{
    background-color: var(--bg-checkbox-grey);
}

.background_object_info_automatic_suspension{
    background-color: var(--bg-automatic-suspension);
}

/*popupConfirmChanges*/

.btn_save_changes_admin_position{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.btn_with_admin{
    width: 100%;
}

.btn_change_color_gray{
    background-color: #bfbfbf;
}


/*overlays*/
.overlay {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: transparent;
    z-index: 1;
}

.overlay_options{
    z-index: 1;
    background: transparent;
}

/* popups of options*/

.content_options{
    background-color: var(--bg-blue);
    color:var(--white-text-1);
    padding: 10px;
    position: absolute;
    z-index: 2;
    animation: animationPopupOptionsActivate .2s forwards;
    border-radius: var(--border-radius-2);
    box-shadow: 0 0 10px var(--gray-shadow-1);
}

.spacing{
    margin-bottom: 4%;
}


/*Animation Popup Options*/

@keyframes animationPopupOptionsActivate {
    from{
    transform: translateY(-50px) scale(0);
    }
    to{
    transform: translateY(0) scale(1);
    }
}

@keyframes animationOpacityActivate {
    from { opacity: 0; }
    to { opacity: 1; }
}
.visibility_menu_admin_vertical{
    display:none;
}
.text_decoration_none{
    text-decoration: none;
}

.visibility_menu_admin{
    display:block;
    background-color: var(--bg-dashboard-grey);
    width: 20%;
    padding: 3% 3% 1.3%;
}

.container_menu_and_search_admin{
    display: flex;
    gap: 2%;
    padding: 4% 3% 0;
}


@media (min-width: 0) and (max-width: 800px) {
    .container_admin{
        display: flex;
        flex-direction: column;
    }
    .manager_control{
        width: 100%;
        gap: 1.5%;
    }
    .visibility_menu_admin{display: none;} 
    .visibility_menu_admin_vertical{display: block;}
    .hide{display:none;}
    .fieldSize17{width: 30%;}
    .fieldSize8{width: 20%;}
    .fieldSize13{width: 30%;}
    .fieldSize15{ width: 30%;}
    .dashborard{margin: 10px 0;}
    
    .popup_admin_save_changes_admin{
        width: 80%;
        padding: 3%;
    }
}

@media (min-width: 801px) and (max-width: 1070px){
    .hide2{display:none;}
    .fieldSize3 {width: 5%;}
    .fieldSize17 {width: 28%;}
    .fieldSize8 {width: 10%;}
    .fieldSize13 {width: 14%;}
}



