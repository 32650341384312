.header {
    background: #FFFF;
}

.center_nav_bar {
    max-width: var(--max-width-container);
    margin: auto;
    /* height: 54px; */
    padding: 15px 20px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup_profile {
    margin: 20px;
}

.logo {
    width: 200px;
}

.nav_login {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
}

.username_header {
    font-family: var(--font-1);
    color: var(--gray-text-2);
}

.simbol_alert {
    width: 10px;
    height: 10px;
    border: 2px solid #FFFF;
    background: red;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
}

.link_header_login {
    text-decoration: none;
    color: var(--bg-blue);
    font-family: var(--font-1);
}

.or_nav_login {
    font-family: var(--font-1);
    color: var(--gray-text-3);
}

/* Nav bar header */
.nav_bar_header {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.scroll_nav_bar {
    overflow-y: auto;
}

.info_nav_bar_user {
    padding: 25px;
    display: flex;
    gap: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.034);
}

.options_nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.034);
}

.notifications_nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.034);
    position: relative;
}

.info_user_profile_nav_bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.username_nav_bar {
    color: var(--gray-text-2);
    font-family: var(--font-1);
    font-size: 18px;
}

.email_nav_bar {
    color: var(--gray-text-3);
    font-size: 15px;
    font-family: var(--font-1);
} 

.notifications {
    padding: 10px 25px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.link_notification {
    text-decoration: none;    
}

.option_notification {
    right: 5em;
    top: 10px;
}

.option_logout {
    /* width: 100%; */
    border-top: 1px solid rgba(0, 0, 0, 0.034);
    /* align-self: flex-start; */
}

.ocult_nav_bar {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 0;
    left: 0;
}

.ocult_popup {
    position: fixed;
    z-index: -1;
    background: rgba(192, 192, 192, 0.534);
    width: 100vw;
    height: 100vh;
}

.padding_info_user {
    width: 50%;
    /* padding: 20px; */
}

.image_profile {
    width: 50%;
    position: relative;
} 

.profile_popup { 
    display: flex;
    justify-content: center;
    align-items: center;
}

.center_popup_profile {
    max-width: 900px;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_profile {
    width: 100%;
    display: flex;
}


@media screen and (max-width: 480px) {
    .username_header {
        display: none;
    }
    
}

@media screen and (max-width: 546px) {
    
    .popup_profile {
        height: 100%;
        flex-direction: column-reverse;
        overflow: scroll !important;
    }
    
    .image_profile {
        width: 100%;     
    }

    .padding_info_user {
        width: 100%;
    }

    .center_popup_profile {
        height: 95%;
    }

    .image_profile {
        height: 50%;
    } 
}

.logo_mobile {
    display: none;
    color: var(--bg-blue);
    font-family: var(--font-1);
}


@media screen and (max-width: 495px) {
    .logo {
        display: none;
    }
    .logo_mobile {
        display: block;
    }
}

