.title_admin{
    color:var(--gray-text-3);
    margin-bottom: 2em;
}

@media (min-width: 320px) and (max-width: 480px) {
    .title_admin{
        width: 90%;
        margin-bottom: 0;
    }

    
}