.menu_admin{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btns_menu_admin{
    display: flex;
    flex-direction: column;
    gap:1em;
    justify-content: space-between;
}

.btn_with_admin{
    width: 100%;
    height: 3em;
    font-size: 1.0625em;
    border-radius: 0.3125em;
    color:var(--white-text-1);
    cursor:pointer;
}

.btn_change_color_gray{
    background-color: var(--btn-background-color);
}

