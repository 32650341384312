.select_input {
    /* width: 245px; */
    width: 100%;
    padding: 13px;
    border: none;
    border-radius: 5px;
    outline: none;
    background: #f9f9f9;
    font-family: var(--font-1);
    font-size: 18px;
    color: var(--gray-text-2);
}

.select_active {
    opacity: 0.2;
    user-select: none;
}

/* Theme input dark */

.input_dark   {
    background: #2b323b;
    color: #FFFF;
} 
