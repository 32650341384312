.contaner_message {
    display: flex;
}

.card_message {
    max-width: 500px;
    border-radius: 5px;
    padding: 15px;
    overflow-wrap: break-word;

}

.message_card {
    font-family: var(--font-1);
    font-size: 17px;
    overflow-wrap: break-word;  
    margin-bottom: 5px;
}

.date_card {
    font-size: 11px;
    font-family: var(--font-1);
}

/*No olvidar colocar la fecha alineada al mensaje derecha o izquierda y cambiar el formato*/

/* Location card */

.in {
    justify-content: flex-end;
    
}

.in > .card_message {
    background: var(--bg-blue);
    align-items:flex-end;
    color: #FFFF;
}

.out {
    justify-content: flex-start;
}

.out > .card_message {
    background: #f5f5f5;
    align-items: flex-start;
    color: var(--gray-text-2);
}