/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

/* Font 1 */
@font-face {
  font-family: 'coco gothic';
  src: url(./Assets/Fonts/CocoGothic/CocoGothic_trial.ttf) format("truetype");
}

:root {
  /* Max width containers */
  --max-width-container: 1450px;

  /* Backgrounds */
  --bg-buttons: #289fa5;
  --clr-titles: #303030;
  --clr-paragrhap: #303030;
  --color-one: #26c9d1;

  --bg-ligth-blue: #F4F8FF;
  --bg-blue: #14A2D6;

  --bg-layout-grey: #f1f1f1;
  --bg-checkbox-grey: #e0e0e0;

  --bg-popup-description: #e0e0e0;

  --bg-dark-1: #393E46;
  --bg-dark-2: #222831;
  --bg-dark-3: #2b323b;

  --btn-background-color:#bfbfbf;
  --bg-automatic-suspension:#d9d9d9;

  /* Borders */
  --gray-border-1: 1px solid rgba(0, 0, 0, 0.034);


  /* Shadows */
  --gray-shadow-1: #9f9f9f33;

  /* Text colos */
  --gray-text-1: #494949;
  --gray-text-2: #6D6D6D;
  --gray-text-3: #858585;
  --gray-text-4: #303030;
  --gray-text-5: #848484;
  --gray-text-6: #9c9c9c;
  --white-text-1: #ffff;

  /* Gradients */
  --color-gradient-1: #616161;
  --color-gradient-2: rgba(104, 104, 104, 0.459);

  /* Border radius */
  --border-radius-1: 10px;
  --border-radius-2: 7px;

  /* Fonts */
  --font-1: 'coco gothic';

  /*status users*/
  --status-yellow: #ffcc00;
  --status-red: #ff3e3e;
  --status-green: #7dd725;
  --status-grey: #6c757d;

  /*border*/

  --bd-checkbox-grey: #848484;
  --bg-dashboard-grey: #ebebeb;
  --font-1: 'Sora', sans-serif;
  --font-2: 'Alata';

  /* Animations */
  --animation-show-slow: show_slow;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-ligth-blue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Buttons */
.input {
  padding: 10px;
  margin: 0 0 1.5em 0;
  border-radius: 5px;
  border: none;
  background: #EBEBEB;
  font-size: 17px;
  outline: none;
  transition: 40ms;
  color: var(--clr-paragrhap);
}

.input:focus {
  outline: 2px solid var(--bg-buttons);
  background: #289fa510;
}

/*text*/
.text_center {
  text-align: center;
}

/*Admin*/
.container_admin {
  height: 100vh;
  display: flex;
}

.manager_control {
  width: 80%;
  padding: 3% 2% 1.3%;
  display: flex;
  flex-direction: column;
  gap: 2.5%;
}

.btn_save_changes_admin_position {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn_save_changes_admin {
  width: 20%;
}

/*Animation Popup Options*/

@keyframes animationPopupOptionsActivate {
  from {
    transform: translateY(-50px) scale(0);
  }

  to {
    transform: translateY(0) scale(1);
  }
}

@keyframes animationOpacityActivate {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*Media Queries*/

@media (max-width: 600px) {
  .menu_admin {
    font-size: 14px;
  }

  .dashboard_header,
  .dashborard {
    font-size: 13px;
  }

  .fieldSize3 {
    width: 6%;
  }

  .fieldSize20 {
    width: 18%;
  }

  .fieldSize8 {
    width: 12%;
  }

  .search_box {
    font-size: 13px;
    width: 80%;
  }

  .content_filter {
    font-size: 13px;
    width: 15%;
  }

  .btn_save_changes_admin {
    font-size: 13px;
    width: 33%;
  }



}


@keyframes show_slow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show_slow_ocult {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
