.title_label_input {
    color: var(--gray-text-1);
    font-family: var(--font-1);
    font-size: 18px;
    margin: 0 0 5px 0;
}

.white_text {
    color: #e7e7e7;
    /* background: transparent; */
}