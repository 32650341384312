.login_main {
    background-image: url('Assets/Backgrounds/LoginBackground.svg');
    background-size: 100% 100% contain;
    background-repeat: no-repeat;
}

.center_main_login {
    max-width: 1100px;
    margin: auto;
    height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5em;
}

.information_login_app {
    max-width: 450px;
    color: #FFFF;
}

.title_info_login_app {
    font-size: 2.5em;
    text-align: center;
    font-family: var(--font-1);
}

.info_login {
    font-family: var(--font-1);
    font-size: 20px;
    margin: 20px 0 0 0;
    line-height: 25px;
    text-align: center;
}

.padding_container_form_login {
    width: 370px;
    padding: 40px;

    display: flex;
    flex-direction: column;
}

.form_login {
    margin: 2.5em 0 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.link_recover_password {
    text-decoration: none;
    color: var(--bg-blue);
    font-family: var(--font-1);
}

.info_register {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-1);
    color: var(--gray-text-2);
}

.link_registry {
    text-decoration: none;
    color: var(--bg-blue);
}

.icon_alert {
    fill: #ac2828;
}

.login_background_image_svg {
    position: absolute;
    width: 90vw;
    height: 100vh;
    z-index: -1;
}

@media screen and (max-width: 925px) {
    .information_login_app {
        display: none;
    }

    .center_main_login {
        flex-direction: column;
    }
}