.content_status_users{
    width: 11.4375em;
}
.op_state_object{
    cursor: pointer;
    position: relative;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 5%;
}


.op_state_object::before{
    content: "";
    display: flex;
    height: 0.625em;
    width: 0.625em;
    border-radius: 50%;
    margin-right: 0.125rem;
}

.yellow::before{
    background-color: var(--status-yellow);
}

.green::before{
    background-color: var(--status-green);
}

.red::before{
    background-color: var(--status-red);
}

.status_object_dashboard{
    display: flex;
    align-items: center;
    gap: 5%;
    cursor:pointer
}

@media (min-width: 0) and (max-width: 480px) {
    .content_status_users{
        right: -14px;
    }
}

@media (min-width: 801px) and (max-width: 1070px) {
    .content_status_users{
        right: -14px;
    }
}

