.interest_card {
    width: 100%;
    display: flex;
    /* height: 10px; */
}

.information_interest_card {
    width: 50%;    
    padding: 32px;
}

.subtitle_interest_card {
    font-family: var(--font-1);
    color: var(--gray-text-3);
    font-size: 18px;
    margin: 0 0 5px 0;
    letter-spacing: 2.5px;
}

.title_information_interest_card {
    font-family: var(--font-1);
    color: var(--gray-text-2);
    font-size: 27px;
    margin: 0 0 10px 0;
}

.image_interest_card {
    width: 50%;
    position: relative;
}

.gradient_image_interest_card {
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(
        90deg, #ffffff 16%, 
        #ffffffc6 42%,
        rgba(255, 255, 255, 0.418) 62%, 
        rgba(255, 255, 255, 0) 100%
    ); */
    z-index: 3;
}

.image_interest_card > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 500px) {
    .interest_card {
        flex-direction: column-reverse;
    }

    .information_interest_card {
        width: 100%;
    }

    .image_interest_card  {
        width: 100%;
    }
}