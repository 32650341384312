.registry_main {
    background-image: url('Assets/Backgrounds/RegistryBackground.svg');
    background-size: 100% 130%;
    background-repeat: no-repeat;
    background-position: right;
}

.registry {
    position: relative;
}

.background_registry {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.background_image {
    position:absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    overflow: hidden;
}   

.background_image_login_svg {
    width: 124%;
    height: 100%; 
}

.background_image_svg {
    position: absolute;
    z-index: -1;
}

.center_main_registry {
    max-width: var(--max-width-container);
    height: 100vh;
    padding: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9em;
} 

.container_form_registry {
    width: 600px;
    transition: ease-in 1s;
}

.padding_form_register {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.header_registry {
    display: flex;
    justify-content: space-between;
    margin: 0 0 5px 0;
}

.link_login_form_register {
    color: var(--bg-blue);
    text-decoration: none;
    font-family: var(--font-1);
}

.form_registry {
    display: flex;
    flex-direction: column;
}

.basic_data {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    animation: var(--animation-show-slow) 200ms;
    margin: 0 0 20px 0;
}

.acount_data {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
}

.profile_user {
    animation: var(--animation-show-slow) 200ms;
}

.subtitle_form_registry {
    padding: 2px 0;
    margin: 20px 0 10px 0;
    color: var(--gray-text-3);
    border-bottom: 2px solid #f9f9f9;
    color: var(--gray-text-1);
    font-family: var(--font-1);
}

.information_app {
    text-align: center;
}

.title_steps {
    color: #FFFF;
    font-family: var(--font-1);
}

.title_info_app {
    color: #FFFF;
    font-size: 3em;
    margin: 0 0 20px 0;
    font-family: var(--font-1);
}

.text_info_registry {
    font-size: 1.2em;
    color: #FFFF;
    width: 500px;
    font-family: var(--font-1);    
}

.input_file_image_profile {
    display: none;
}

.title_preview_image_profile {
    font-size: 1.2em;
    color: var(--gray-text-1);
    width: 500px;
    font-family: var(--font-1);    
}

.preview_image_profile {
    position: relative;
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.image_profile_registry {
    width: 300px;
    height: 300px;
    border: 3px dashed var(--gray-text-3);
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_plus_image{ 
    width: 50px;
    fill: var(--gray-text-3);
}

.image_preview_profile {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.button_upload_image_profile{ 
    position: absolute;
    right: 120px;
}

/* Steps */
.steps_registry {
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.step {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all ease 5s;
}

.step:hover {
    cursor: pointer;
}

.step_number {
    border: 2px solid #1583ac;
    padding: 10px 18px;
    border-radius: 50%;
    font-family: var(--font-1);
    color: #1583ac; 
}

.step_info {
    font-family: var(--font-1);
    color: #1583ac; 
}

.current_step > .step_number {
    color: #FFFF;
    font-weight: 700;
    border: 2px solid  #FFFF;
}

.current_step > .step_info {
    color: #FFFF;
}

.complete_step > .step_number {
    color: #FFFF;
    font-weight: 700;
    border: 2px solid #FFF;
}

.complete_step > .step_info {
    color: #FFFF;
}

.steps_registry > .c_line_step:last-child {
    display: none;
}

.step_info {
    font-family: var(--font-1);
}

.line_step {
    width: 100px;
    height: 2px;
    background: #1583ac;
    border-radius: 5px;
}

.line_current > .line_step {
    background: #FFFF;
}

.line_complete > .line_step {
    background: #FFFF;
}
                              
.container_buttons_registry{display: flex; gap: 2em;}

@media screen and (max-width: 925px) {
    .information_app {display: none;}
    .center_main_login {flex-direction: column;}
}

@media (min-width: 320px) and (max-width: 1333px) {
    .header_registry{flex-direction: column;align-items: center;}
    .basic_data{justify-content: space-around;}
    .container_buttons_registry{justify-content: center;}
}