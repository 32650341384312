.content_filter{
    width: 10%;
    position: relative;
}

.btn_search_filter_admin_users{
    width: 100%;
    height: 100%;
}

.filter_options{
    top: 103%;
    right: 0;
    width: 13.75rem;
    z-index: 1;
}


.icon_filter_admin{
    display: none;
    position: absolute;
    left: -23px;
}
@media (min-width: 0) and (max-width: 800px) {
    .icon_filter_admin{
        display: block;
    }
    .remove_button{
        display: none;
    }
    .content_filter{
        width: 0;
        display: flex;
        align-items: center;
    }
    .filter_options{
        top: 27px;
    }
    
}