.dashboard_header{
    background-color: var(--bg-dashboard-grey);
    padding: 1% 2%;
    border-radius: 1em;
    display: flex;
    gap: 2%;
    color: var(--bd-checkbox-grey);
    align-items: center;
}
.reportColor{color:var(--status-red)}

.fieldSize3{width: 3%;}
.fieldSize21{width: 21%;}
.fieldSize22{width: 22%;}
.fieldSize5{width: 5%;}
.fieldSize8{width: 8%;}
.fieldSize15{width: 15%;}
.fieldSize13{width: 13%;}
.fieldSize17{width: 17%;}
.fieldSize20{width: 20%;}

