.title_new_service {
    font-family: var(--font-1);
    margin: 0 0 1.5em 0;
    color: var(--gray-text-1);
    font-weight: 100;
}

.section_form_new_service {
    height: 100%;
    animation: 500ms var(--animation-show-slow);

    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.preview_image_service {
    width: 60%;
}

.input_preview_image_service {
    display: none;
}

.label_preview_image_service {
    height: 100%;
}

.open_preview_image_service {
    width: 100%;
    height: 500px;
    /* border: 2px solid red; */
    background: #f4f8ff;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.open_preview_image_service > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.quit_preview_image {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all ease-in 100ms;

    display: flex;
    justify-content: center;
    align-items: center;
}

.information_quit_preview_image {
    display: none;

    font-family: var(--font-1);
    color: #FFFF;
    font-size: 20px;
}

.quit_preview_image:hover  {
    background: #83838385;
}

.quit_preview_image:hover > .information_quit_preview_image {
    display: block;
}

.form_new_service {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.state_new_service {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info_add_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.information_add_image {
    width: 150px;
    text-align: center;
    font-family: var(--font-1);
    color: var(--gray-text-3);
}

.icon_plus_add_image {
    width: 50px;
    fill: var(--gray-text-3);
}