.center_main_search {
    max-width: var(--max-width-container);
    margin: 2em auto;
    padding: 0 20px;

    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.search_info {
    width: 20%;
}

.header_search_info {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.034);
}

.length_results {
    font-family: var(--font-1);
    color: var(--gray-text-3);
}

.title_value_search {
    font-size: 30px;
}

.filter_search_info {
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subtitle_filter_search {
    font-family: var(--font-1);
    color: var(--gray-text-2);
    font-size: 17px;
}

.search_services {
    width: 80%;
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.section_hight_resolution {
    width: 100%;
    display: flex;
    flex-wrap: wrap;    
    flex-direction: column;
    gap: 10px;
}

.section_slow_resolution {
    display: none;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

@media screen and (max-width: 1360px) {
    .center_main_search {
        flex-direction: column;
    }

    .search_info {
        width: 100%;
    }

    .search_services {
        width: 100%;
    }

    .section_slow_resolution {
        display: block;
    }

    .section_hight_resolution {
        display: none;
    }
}

@media screen and (min-width: 1360px) {
    .section_slow_resolution {
        display: none;
    }
}