.content_type_report{
    left: -1.125em;
    width: 8.6875em;
}

.typ_report{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.625em;
}

.pointer_userSelect_none{
    cursor: pointer;
    user-select: none;
}