.center_main_forgotten_password {
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.form_forgotten_password {
    max-width: 500px;
}

.padding_forgotten_password {
    padding: 40px;

    display: flex;
    flex-direction: column; 
    gap: 20px;
}

.header_forgotten_password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 30px;
}

.incon_unlock {
    width: 200px;
}

.title_header_forgotten_password {
    font-family: var(--font-1);
    text-align: center;
    font-size: 2.4em;
}

.info_header_forgotten_password {
    font-family: var(--font-1);
    text-align: center;
    color: var(--gray-text-3);
}

.form_forgotten_password {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.section_new_pasword {
    animation: var(--animation-show-slow) 300ms;
}

.btn_login {
    text-align: center;
    margin: 20px 0 0 0;
    font-size: 20px;
    color: var(--bg-blue);
    font-family: var(--font-1);
}

.btn_login:hover {
    cursor: pointer;
}