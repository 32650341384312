.object_status{
    background-color: var(--bg-blue);
    border-radius: 10px;
    display: flex;
    color:var(--white-text-1);
    align-items: center;
    gap: 0.5em;
    padding: 1%;
}

.delete_user_status{
    cursor: pointer;
}