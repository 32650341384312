.input_text {
    width: 100%;
    padding: 13px;
    border: none;
    border-radius: 5px;
    outline: none;
    background: #f9f9f9;
    font-family: var(--font-1);
    font-size: 18px;
    transition: 500ms ease;
}

.input_not_validate {
    background: #ffebeb;
    outline: 2px solid #ac2828;
    color: #ac2828;
}

.input_not_validate::placeholder {
    color: #cf7878;
}

.input_message_error {
    margin: 7px 0 0 0;
    font-family: var(--font-1);
    color: #a10f0f;
}

/* Theme input dark */

.input_dark   {
    background: #2b323b;
    color: #FFFF;
} 
