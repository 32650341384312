.dashborard{
    background-color:var(--bg-dashboard-grey);
    height: 100%;
    border-radius: 1em;
    padding: 1%;
    display: flex;
    flex-direction: column;
}

.scroll{
    overflow: hidden;
    overflow-y: auto;
}

.scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scroll::-webkit-scrollbar:vertical {
    width:8px;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: var(--bd-checkbox-grey);
    border-radius: 20px;
}
.center_message{
    align-items: center;
    justify-content: center;
}
