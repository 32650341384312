.center_chat {
    max-width: var(--max-width-container);
    height: calc( 100vh - 150px);
    margin: 2em auto;
    padding: 0 20px;

    display: flex;
    gap: 20px;
}

.aside_chat {
    width: 30%;
    padding: 25px;
}

.header_aside_chat {
    padding: 0 0 20px 0;
}

.cards_aside_chat {
    /* height: 300px; */
    gap: 15px;

    display: flex;
    flex-direction: column;
}

.main_messages {
    width: 70%;
}

.header_messages_chat {
    padding: 25px;
    border-bottom: var(--gray-border-1);

    display: flex;
    justify-content: space-between;
}

.information_user_header_chat {
    display: flex;
    align-items: center;
    gap: 15px;
}

.name_user_header_chat {
    font-size: 25px;
    font-family: var(--font-1);
    color: var(--gray-text-2);
}

.main_messages_chat {
    height: calc(100% - 41px);
    padding: 0 20px 20px 20px;

    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.messages_chat {
    height: calc(100% - 95px);
    overflow-y: scroll;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 15px;
}

.messages_chat::-webkit-scrollbar {
    width: 5px;
}

.messages_chat::-webkit-scrollbar-track {
    /* background: #f0f0f0; */
    border-radius: 2px;
}

.messages_chat::-webkit-scrollbar-thumb {
    background: #c5c5c552;
    border-radius: 2px;
}

.input_message_chat {
    display: flex;
}

.container_input_message_chat {
    width: 100%;
}

.message_chat {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.conversation_message {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.test_conversation_message {
    font-family: var(--font-1);
    color: var(--gray-text-2);
    font-size: 17px;
    margin: 0 0 20px 0;
}

.icon_message_chat {
    width: 300px;
}

.title_app_info_chat {
    font-size: 2.5em;
    font-family: var(--font-1);
    color: var(--gray-text-3);
}

.info_chat {
    font-family: var(--font-1);
    width: 400px;
    text-align: center;
    border-top: 2px solid #f0f0f0;
    padding: 10px 0;
    color: #b8b8b8;
}

/* Popup add cualification */

.ceneter_popup_add_cualification {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.add_service_cualification {
    max-width: 500px;
    padding: 30px;

    display: flex;
    flex-direction: column;
}

.title_service_cualification {
    font-family: var(--font-1);
    color: var(--gray-text-1);
    font-size: 27px;
    margin: 0 0 5px 0;
}

.information_service_cualification {
    font-family: var(--font-1);
    color: var(--gray-text-3);
    font-size: 18px;
}

.select_cualification {
    display: flex;
    justify-content: center;
    margin: 0 0 20px 0;
}


.actions_add_service_cualification {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.section_info_chat {
    width: 70%;
}

.btn_exit_chat {
    width: 45px;
    fill: var(--gray-text-2);
}

@media screen and (max-width: 850px) {
    .main_messages {
        width: 100%;
    }

    .section_info_chat {
        display: none;
    }

    .aside_chat {
        width: 100%;
    }

    .close_chat {
        display: none;
    }
}

@media screen and (min-width: 850px) {
    .aside_chat {
        display: block !important;
    }

    .btn_exit_chat {
        display: none;
    }
}